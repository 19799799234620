@use '../util' as *;

h1,
h2,
h3 {
	margin-top: 0;
	line-height: 1.1;
}

a,
a:visited,
a:active {
	text-decoration: none;
	cursor: pointer;
}
a:active{
	color: gray;
}
a:hover{
	color: lightgray;
}
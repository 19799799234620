@use '../util' as *;

h1{
    font-family: lobster;
    font-size: rem(70);
    font-weight: 700;
    padding-top: rem(55);
    color:rgb(0, 0, 0) ;
}
.space{
    padding-left: rem(25);

}
.header{
    border: rem(1) solid var(--black);
    border-radius: rem(10);
    width: rem(1349);
    height: rem(200);
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right, rgb(255, 255, 255),rgb(0, 0, 0));
}
.header2{
    border: rem(1) solid var(--black);
    border-radius: rem(10);
    width: rem(1365);
    height: rem(200);
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right, rgb(255, 255, 255),rgb(0, 0, 0));
}
.header3{
    border: rem(1) solid var(--black);
    border-radius: rem(10);
    width: rem(1360);
    height: rem(200);
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right, rgb(255, 255, 255),rgb(0, 0, 0));
}
.links{
    display: flex;
    justify-content: center;
    font-size: rem(25);
    background-color: black;

:not(:last-child){
    margin-right: rem(100);
}
}
.head{
    display: flex;
    justify-content: center;
    padding-top: rem(65);
    font-size: rem(100);
    font-family: lobster;
    position: absolute;
}
.mun{
    padding-top: rem(180);
    font-family: Itim;
    font-size: rem(20);
    color: var(--black);
}
.mun1{
    padding-top: rem(180);
    font-family: Itim;
    font-size: rem(20);
    color: var(--black);
    padding-bottom: rem(50);
}
.para1{
    padding-top: rem(50);
    padding-right: rem(20);
    font-family: Itim;
    font-size: rem(20);
    width:rem(1000);
    padding-left:rem(400);
}
.para2{
    padding-bottom: 0;
    padding-right: rem(20);
    font-family: Itim;
    font-size: rem(20);
    width:rem(1000);
    padding-left:rem(400);
}
.para3{
    padding-bottom: 0;
    font-family: Itim;
    font-size: rem(20);
}
.para4{
    padding-bottom: 0;
    padding-left: rem(540);
    font-family: Itim;
    font-size: rem(20);

}
.bg{
    display:flex;
    justify-content: center;
}
.bg1{
    display:flex;
    justify-content: center;
}
.form{
    color: rgb(0, 0, 0);
    padding: rem(190);
    font-size: rem(25);
    font-family: itim;
}
.a{
    color: rgb(255, 255, 255);
    font-size: rem(25);
    font-family: itim;
}
.form:hover{
    color: rgb(77, 77, 77);
}
.profile{
    background-color:aqua;
    width: rem(50);
    height: rem(50);
    border-radius: rem(10);
}
.profile2{
    background-color:aqua;
    width: rem(50);
    height: rem(50);
    border-radius: rem(10);
}
.profile3{
    background-color:aqua;
    width: rem(50);
    height: rem(50);
    border-radius: rem(10);
}

